import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import swal from 'sweetalert';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const styles = ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin:theme.spacing(3, 0, 2),
    },
    media: {
        margin: 10,
        width: 180,
        height: 120,
      },
})



class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            callBack: false,
            name: '',
            emailMobile: '',
            feedback: '',
            category:''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(name)
        console.log(value)
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state);
        const feedback = this.state;
        this.setState({
            callBack: false,
            name: '',
            emailMobile: '',
            feedback: '',
            category:''
        });
        axios.post(`https://crickinngdom-com092018.firebaseio.com/feedback.json`, { feedback })
        .then(res => {
            console.log(res);
            console.log(res.data);
            swal("Thank You!", "Thanks for your valuable feedback!", "success")
        })
    }




render() {
    const classes = this.props.classes;

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <CardMedia
                className={classes.media}
                image={require("../../assets/logo.png")}
                title="CricKingdom"
                />
                <Typography spacing={2} component="h1" variant="h5">
                    Feedback
                    </Typography>
                <Box fontStyle="italic">
                    <Typography spacing={2} variant="subtitle1">
                        We love to hear from you
                    </Typography>
                </Box>
                <form onSubmit={this.handleSubmit} className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextField
                                autoComplete="name"
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                value={this.state.name}
                                autoFocus
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="emailMobile"
                                label="Email/Mobile"
                                name="emailMobile"
                                value={this.state.emailMobile}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl component="fieldset">
                        <FormLabel component="legend">Category</FormLabel>
                        <RadioGroup aria-label="category" name="category" value={this.state.category} onChange={this.handleInputChange} row>
                            <FormControlLabel
                            value="Academy"
                            control={<Radio color="primary" />}
                            label="Academy"
                            labelPlacement="end"
                            />
                            <FormControlLabel
                            value="Store"
                            control={<Radio color="primary" />}
                            label="Store"
                            labelPlacement="end"
                            />
                            <FormControlLabel
                            value="FacilityBooking"
                            control={<Radio color="primary" />}
                            label="Facility Booking"
                            labelPlacement="end"
                            />
                            <FormControlLabel
                            value="CoachingBooking"
                            control={<Radio color="primary" />}
                            label="Coaching Booking"
                            labelPlacement="end"
                            />
                            <FormControlLabel
                            value="Others"
                            control={<Radio color="primary" />}
                            label="Others"
                            labelPlacement="end"
                            />
                        </RadioGroup>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="feedback"
                                label="Feedback"
                                multiline
                                rows="4"
                                id="feedback"
                                value={this.state.feedback}
                                onChange={this.handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox name="callBack" onChange={this.handleInputChange} checked={this.state.callBack} color="primary" />}
                                label="I wish to talk directly with someone from CricKingdom Team."
                            />
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary">
                            Send
                        </Button>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
}

export default withStyles(styles)(Feedback);